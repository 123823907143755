

/**
 *
 * @param t list of list of list of tokens
 * @param i index of paragraph
 * @param j index of sentence
 * @param k index of token
 */
export function getNext(t, i, j, k) {
  if(t.length === 0) {
    // return "";
    return null;
  }
  if(i === t.length - 1) {
    if(j === t[i].length - 1) {
      if(k === t[i][j].length - 1) {
        // return t[i][j][k];
        return [i, j, k];
      } else {
        // return t[i][j][k+1];
        return [i, j, k+1];
      }
    } else {
      if(k === t[i][j].length - 1) {
        // return t[i][j+1][0];
        return [i, j+1, 0];
      } else {
        // return t[i][j][k+1];
        return [i, j, k+1];
      }
    }
  } else {
    if(j === t[i].length - 1) {
      if(k === t[i][j].length - 1) {
        // return t[i+1][0][0];
        return [i+1, 0, 0];
      } else {
        // return t[i][j][k+1];
        return [i, j, k+1];
      }
    } else {
      if(k === t[i][j].length - 1) {
        // return t[i][j+1][0];
        return [i, j+1, 0];
      } else {
        // return t[i][j][k+1];
        return [i, j, k+1];
      }
    }
  }
}

/**
     *
     * @param t list of list of list of tokens
     * @param i index of paragraph
     * @param j index of sentence
     * @param k index of token
     * @returns
     */
export function getPrev(t, i, j, k) {
  if(t.length === 0) {
    return null;
    // return "";
  }
  if(k === 0) {
    if(j === 0) {
      if(i === 0) {
        return null;
        // return ""; // t[i][j][k]; // There is no previous
      } else {
        const nI = t[i-1].length;
        const nJ = t[i-1][nI-1].length;
        // return t[i-1][nI-1][nJ-1]
        return [i-1, nI-1, nJ-1];
      }
    } else {
      const nJ = t[i][j-1].length;
      // return t[i][j-1][nJ-1];
      return [i, j-1, nJ-1];
    }
  } else {
    // return t[i][j][k-1];
    return [i, j, k-1];
  }
}