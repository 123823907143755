import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        raw: "Kringla heimsins, sú er mannfólkit byggvir, er mjǫk vágskorin; ganga hǫf stór ór útsjánum inn í jǫrðina. \n" +
          "Er þat kunnigt, at haf gengr frá Nǫrvasundum ok alt út til Jórsala-landz; af hafinu gengr langr hafsbotn til landnorðrs, er heitir Svarta-haf. \n" +
          "Þá skilr heimsþriðjungana; heitir fyrir austan Ásíá, en fyrir vestan kalla sumir Európá, en sumir Éneá. \n" +
          "En norðan at Svarta-hafi gengr Svíþjóð in mikla eða in kalda; Svíþjóð ina miklu kalla sumir menn eigi minni en Serkland it mikla: sumir jafna henni við Bláland it mikla; inn nørðri hlutr Svíþjóðar liggr óbyggðr af frosti ok kulða, svá sem inn syðri hlutr Blálandz er auðr af sólar-bruna. \n" +
          "Í Svíþjóð eru stórheruð mǫrg; þar eru ok margs konar þjóðir ok margar tungur; þar eru risar ok þar eru dvergar, þar eru blámenn, ok þar eru margs konar undarligar þjóðir; þar eru ok dýr ok drekar furðuliga stórir. \n" +
          "Ór norðri frá fjǫllum þeim, er fyrir útan eru byggð alla, fellr á um Svíþjóð, sú er at réttu heitir Tanais, hon var forðum kǫlluð Tanakvísl eða Vanakvísl; hon kømr til sjávar inn í Svarta-haf. \n" +
          "Í Vanakvíslum var þá kallat Vanaland eða Vanaheimr. \n" +
          "Sú á skilr heimsþriðjungana; heitir fyrir austan Ásíá, en fyrir vestan Európá. \n" +
          "\n" +
          "Fyrir austan Tanakvísl í Ásíá var kallat Ásaland eða Ásaheimr, en hǫfuðborgin, er var í landinu, kǫlluðu þeir Ásgarð. \n" +
          "En í borginni var hǫfðingi sá, er Óðinn var kallaðr; þar var blótstaðr mikill. \n" +
          "Þat var þar siðr, at xii. hofgoðar váru œztir; skyldu þeir ráða fyrir blótum ok dómum manna í milli; þat eru díar kallaðir eða drótnar; þeim skyldi þjónostu veita ok lotning alt fólk. \n" +
          "Óðinn var hermaðr mikill ok mjǫk víðfǫrull ok eignaðisk mǫrg ríki; hann var svá sigrsæll, at í hverri orrostu fekk hann gagn; ok svá kom, at hans menn trúðu því, at hann ætti heimilan sigr í hverri orrostu. \n" +
          "Þat var háttr hans, ef hann sendi menn sína til orrostu eða aðrar sendifarar, at hann lagði áðr hendr í hǫfuð þeim ok gaf þeim bjának; trúðu þeir, at þá myndi vel farask. \n" +
          "Svá var ok um hans menn, hvar sem þeir urðu í nauðum staddir á sjá eða á landi, þá kǫlluðu þeir á nafn hans, ok þótti jafnan fá af því fró; þar þóttusk þeir eiga alt traust, er hann var. Hann fór opt svá langt í brot, at hann dvalðisk í ferðinni mǫrg misseri. ",
        tokenized: [],
        annotations: [],
        annotatedLemmata: [],
        annotatedPOS: [],

    },
    mutations: {
        updateAnnotations(state, payload) {
            state.annotations = payload.annotations;
        },
        updateRaw(state, payload) {
            state.raw = payload.raw;
        },
        updateTokenized(state, payload) {
            state.tokenized = payload.tokenized;
            state.annotatedLemmata = JSON.parse(JSON.stringify(state.tokenized));
              for(let i = 0; i < state.tokenized.length; i++) {
                for(let j = 0; j < state.tokenized[i].length; j++) {
                  for(let k = 0 ; k < state.tokenized[i][j].length; k++) {
                    state.annotatedLemmata[i][j][k] = "";
                  }
                }
              }
              state.annotatedPOS = JSON.parse(JSON.stringify(state.tokenized));
              for(let i = 0; i < state.tokenized.length; i++) {
                for(let j = 0; j < state.tokenized[i].length; j++) {
                  for(let k = 0 ; k < state.tokenized[i][j].length; k++) {
                    state.annotatedPOS[i][j][k] = "";
                  }
                }
              }
        },
        updateAnnotatedLemma(state, payload) {
            state.annotatedLemmata = payload.annotatedLemmata;
        },
        updateAnnotatedPOS(state, payload) {
            state.annotatedPOS = payload.annotatedPOS;
        }
    },
    getters: {

    }
});

export default store;