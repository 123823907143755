<template>
  <b-container>
    <h2>Definitions</h2>
    <b-row v-if="networkError">
        <b-col align-self="center" class="my-5">
          <p></p>
        </b-col>
      </b-row>
      <b-row v-if="definitions.length === 0 && !networkError" class="mx-auto">
        <b-col align-self="center" class="my-5">
          <b-spinner label="Loading data..."/>
        </b-col>
      </b-row>
    <b-row v-else-if="definitions.length > 0 && !networkError">
      <b-col cols="12">
      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1">
      <div v-for="(chapter, i) in definitions" :key="`chapter-${i}`">
        <div v-for="(sentence, j) in chapter" :key="`sentence-${j}`">
          <div v-for="(lemma, k) in sentence" :key="`lemma-${k}`">
<!--            <p style="text-align: start">{{ i+1 }}-{{ j+1 }}-{{ k+1 }}: {{ lemma[0] }}</p>-->
            <b-button block v-b-toggle="`collapse-${i}-${j}-${k}`" class="m-1">{{ lemma[0] }}</b-button>
            <b-collapse :id="`collapse-${i}-${j}-${k}`" accordion="my-accordion" role="tabpanel">
              <b-card>
                <p style="text-align: start; justify-content: start" v-html="renderDefinition(lemma[1])"/>
              </b-card>
            </b-collapse>

          </div>

        </div>
      </div>
        </b-card>
      </div>
        </b-col>
    </b-row>

  </b-container>
</template>

<script>
import axios from "axios";
// import {jsPDF} from "jspdf";

export default {
  name: "Definitions",
  data: function() {
    return {
      definitions: [],
      networkError: false
    }
  },
  methods: {
    fetchDefinitions: function () {
      axios.get("https://api.reginsmal.clementbesnier.eu/text/lemmata/").then(
          response => {
            this.definitions = response.data.result;
          }
      )
    },
    renderDefinition(text) {
      return text.replaceAll("\n", "<br>");
    },
    generatePDF() {
      // const doc = new jsPDF({
      //   orientation: "portrait",
      //   unit: "cm",
      // });

      // const myFont =
      // doc.addFont("Junicode-normal")
      // doc.setFont("Junicode-normal")
      // doc.text("");
      // doc.save("definitions.pdf");
    },
  },
  mounted() {
    this.fetchDefinitions();
  },
  watch: {
    // definitions(newValue) {
    //
    //
    // }
  }

}
</script>

<style scoped>

</style>