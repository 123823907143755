import Vue from "vue";
import VueRouter from "vue-router";
import Reginsmal from "@/components/Reginsmal";
import PosMenota from "@/components/PosMenota";
import Definitions from "@/components/Definitions";
import Annotator from "@/components/Annotator";

Vue.use(VueRouter);

let router = new VueRouter({
    mode: "history",
    routes: [
        {
            path: "/reginsmal/text",
            component: Reginsmal
        },
        {
            path: "/reginsmal/menota-pos-annotator",
            component: PosMenota
        },
        {
            path: "/reginsmal/definitions",
            component: Definitions
        },
        {
            path: "/reginsmal/annotator",
            component: Annotator
        }
    ],
});

export default router;
