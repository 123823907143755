<template>
  <div id="app">

    <b-container>
    <h2>Annotations</h2>

      <b-row class="my-3">
        <b-col><b-button class="btn " to="/reginsmal/text">Reginsmal text</b-button></b-col>
        <b-col><b-button to="/reginsmal/menota-pos-annotator">Menota POS annotator</b-button></b-col>
        <b-col><b-button to="/reginsmal/definitions">Dictionary</b-button></b-col>
        <b-col><b-button to="/reginsmal/annotator">Annotator</b-button></b-col>
      </b-row>

    <router-view/>
    <p>Clément Besnier - 2021 <a href="https://www.clementbesnier.fr">www.clementbesnier.fr</a></p>
    </b-container>
  </div>
</template>

<script>
// import Reginsmal from "@/components/Reginsmal";
// import PosMenota from "@/components/PosMenota";

export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2c3e50;*/
  margin-top: 20px;
}
</style>
