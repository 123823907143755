<template>
  <b-container>
    <b-row>
      <p class="paragraph">
        The <em>Reginsmal</em> or the lay of Reginn is a poem written in Old Norse.
        It is a part of a compilation of divine/heroic poems called the Poetic Edda.
        (Photographies of the manuscript
          <a href="http://www.germanicmythology.com/works/CodRegIMAGES/CR56.jpg">first page</a>,
          <a href="http://www.germanicmythology.com/works/CodRegIMAGES/CR57.jpg">second page</a>,
          <a href="http://www.germanicmythology.com/works/CodRegIMAGES/CR58.jpg">third page</a>,
          <a href="http://www.germanicmythology.com/works/CodRegIMAGES/CR59.jpg">fourth page</a>).
      </p>
    </b-row>
    <hr/>
    <b-row v-if="networkError">
      <b-col align-self="center" class="my-5">
        <p></p>
      </b-col>
    </b-row>
    <b-row v-if="annotations.length === 0 && !networkError" class="mx-auto">
      <b-col align-self="center" class="my-5">
        <b-spinner label="Loading data..."/>
      </b-col>
    </b-row>
    <b-row v-else-if="annotations.length > 0 && !networkError">
      <p class="paragraph" v-for="(lg, index_lg) in annotations" :key="`lg-${index_lg}`">
        <span v-for="(l, index_l) in lg" :key="`l-${index_l}`">
          <span v-for="w in l" :key="w.key">
            <span :id="w.key">{{ w.norm }} </span>
            <b-popover
              :target="w.key"
              :title="`${w.norm}`"
              triggers="hover"
              class="popover-style">
              <span class="popover-item-underline">facs</span>: {{w.facs}}
              <br/>
              <span class="popover-item-underline">dipl</span>: {{w.dipl}}
              <br/>
              <span class="popover-item-underline">norm</span>: {{w.norm}}
              <br/>
              <span class="popover-item-underline">lemma</span>: {{w.lemma}}
              <br/>
              <span class="popover-item-underline">pos</span>: {{w.pos}}
              <br/>
              <span class="popover-item-underline">definition:</span> {{w.definition}}
            </b-popover>
          </span>
          <br/>
        </span>
      </p>
    </b-row>
  </b-container>

</template>

<script>
import axios from "axios";

export default {
name: "Reginsmal",
  data: function () {
    return {
      lemmata: [],
      networkError: false
    }
  },
  mounted() {
    if(this.annotations.length === 0) {
      this.retrieveTextWithAnnotations()
    }

  },
  // computed: {
  //   text: function() {
  //     let res = "";
  //     this.annotations.forEach((lg) => {
  //       res = res + "\n\n";
  //       lg.forEach((l) => {
  //         res = res + "\n"
  //         l.forEach((w) => {
  //           if(typeof w === "string") {
  //             res = res + " " + w;
  //           } else {
  //             res = res + " " + w.norm;
  //           }
  //         });
  //         console.log(res)
  //       });
  //     });
  //     return res;
  //     // let res = [];
  //     //
  //     // res = this.annotations.map((p) => {
  //     //
  //     //   p.map((l) => {
  //     //     return l.map((w) => {
  //     //       if(typeof w === "string") {
  //     //         return w;
  //     //       } else {
  //     //         if (w.hasAttribute("norm")) {
  //     //           return w.norm;
  //     //         }
  //     //       }
  //     //     })
  //     //   })
  //     // });
  //     // return res;
  //
  //   }
  // },
  methods: {
    renderEntry(word) {
      return "<div style='overflow-y: scroll;'>facs: "+word.facs+"<br/>dipl: "+word.dipl+"<br/>norm: "+word.norm+"<br/>definition: "+word.definition+"</div>";
    },
    retrieveEntry(lemma) {
        console.log(lemma);
        return axios.get("/dictionary/"+lemma).then(
                (response) => {
                  if(!response.data.error && response.data.result) {
                    return response.data.result;
                  }
                  return "";
                }
        );
      },
    retrieveTextWithAnnotations() {
      axios.get("/text").then(
          (response) => {
            if(response.data.result) {
              let annotations = response.data.result;
              this.$store.commit('updateAnnotations', {annotations});
              // this.annotations = response.data.result;
            } else {
              // this.annotations = [];
              this.$store.commit('annotations', {annotations: []});
            }
          }
      ).catch(
          reason => {
            console.log(reason);
            this.networkError = true;
          }
      );
    },
  },
  computed: {
    annotations: function() {
      return this.$store.state.annotations;
    }
  }
}
</script>

<style scoped>
/*.popover-body {*/
/*  overflow-y: auto;*/
/*}*/

.paragraph {
  text-align: left;
  margin-left: 5%;
  margin-right: 5%;
}

.popover-style {
  overflow-y: scroll; height: 300px;
}

.popover-item-underline {
  text-decoration: underline;
}
</style>