<template>
  <b-container fluid="xl">
    <b-row>
      <h1 class="mx-auto">Menota parts-of-speech tags</h1>
    </b-row>
    <b-row class="py-3">
      <b-col cols="10">
          <b-input-group prepend="Rendering: " class="border p-3" style="width: 400px; float: left;">
            <b-form-input readonly :value="rendering"/>
            <b-input-group-append>
              <b-button class="btn btn-primary" id="copy-btn" @click="copyRendering">Copy</b-button>
            </b-input-group-append>
          </b-input-group>
        <button class="mx-auto p-3 btn btn-primary" @click="refresh">Refresh</button>
      </b-col>
      <b-tooltip target="copy-btn" triggers="manual" :show="showingCopyMessage">Copied</b-tooltip>
      <input type="hidden" id="rendering" :value="rendering"/>
    </b-row>
    <b-row class="py-3">
      <b-col>
        <b-form-group label="Word class" class="d-flex text-left">
          <b-form-radio v-model="wordClass" name="word-class" value="xNC">Common noun</b-form-radio>
          <b-form-radio v-model="wordClass" name="word-class" value="xNP">Proper noun</b-form-radio>
          <b-form-radio v-model="wordClass" name="word-class" value="xAJ">Adjective</b-form-radio>
          <b-form-radio v-model="wordClass" name="word-class" value="xPE">Personal pronoun</b-form-radio>
          <b-form-radio v-model="wordClass" name="word-class" value="xPQ">Interrogative pronoun</b-form-radio>
          <b-form-radio v-model="wordClass" name="word-class" value="xPI">Indefinite pronoun</b-form-radio>
          <b-form-radio v-model="wordClass" name="word-class" value="xDP">Possessive determiner</b-form-radio>
          <b-form-radio v-model="wordClass" name="word-class" value="xDD">Demonstrative determiner</b-form-radio>
          <b-form-radio v-model="wordClass" name="word-class" value="xDQ">Quantifier determiner</b-form-radio>
          <b-form-radio v-model="wordClass" name="word-class" value="xPD">Pronoun/Determiner</b-form-radio>
          <b-form-radio v-model="wordClass" name="word-class" value="xNA">Cardinal numeral</b-form-radio>
          <b-form-radio v-model="wordClass" name="word-class" value="xNO">Ordinal numeral</b-form-radio>
          <b-form-radio v-model="wordClass" name="word-class" value="xVB">Verb</b-form-radio>
          <b-form-radio v-model="wordClass" name="word-class" value="xAV">Adverb</b-form-radio>
          <b-form-radio v-model="wordClass" name="word-class" value="xAT">Articles</b-form-radio>
          <b-form-radio v-model="wordClass" name="word-class" value="xAP">Preposition</b-form-radio>
          <b-form-radio v-model="wordClass" name="word-class" value="xCC">Coordinating conjunction</b-form-radio>
          <b-form-radio v-model="wordClass" name="word-class" value="xCS">Subordinating conjunction</b-form-radio>
          <b-form-radio v-model="wordClass" name="word-class" value="xIT">Interjection</b-form-radio>
          <b-form-radio v-model="wordClass" name="word-class" value="xIM">Infinitive marker</b-form-radio>
          <b-form-radio v-model="wordClass" name="word-class" value="xRP">Relative particle</b-form-radio>
          <b-form-radio v-model="wordClass" name="word-class" value="xUA">Unassigned</b-form-radio>
        </b-form-group>
      </b-col>
      <b-col>
      <div v-if="isWordClass('xNC')" class="d-flex text-left">
        <CustomRadioGroup label="g" v-model="genderSelected" :options="genderClass"/>
        <CustomRadioGroup label="n" v-model="numberSelected" :options="numberClass"/>
        <CustomRadioGroup label="c" v-model="caseSelected" :options="caseClass"/>
        <CustomRadioGroup label="s" v-model="speciesSelected" :options="speciesClass"/>
      </div>
      <div v-if="isWordClass('xNP')" class="d-flex text-left">
        <CustomRadioGroup label="g" v-model="genderSelected" :options="genderClass"/>
        <CustomRadioGroup label="n" v-model="numberSelected" :options="numberClass"/>
        <CustomRadioGroup label="c" v-model="caseSelected" :options="caseClass"/>
        <CustomRadioGroup label="s" v-model="speciesSelected" :options="speciesClass"/>
      </div>
      <div v-if="isWordClass('xAJ')" class="d-flex text-left">
        <CustomRadioGroup label="r" v-model="gradeSelected" :options="gradeClass"/>
        <CustomRadioGroup label="g" v-model="genderSelected" :options="genderClass"/>
        <CustomRadioGroup label="n" v-model="numberSelected" :options="numberClass"/>
        <CustomRadioGroup label="c" v-model="caseSelected" :options="caseClass"/>
        <CustomRadioGroup label="s" v-model="speciesSelected" :options="speciesClass"/>
      </div>
      <div v-if="isWordClass('xPE')" class="d-flex text-left">
        <CustomRadioGroup label="p" v-model="personSelected" :options="personClass"/>
        <CustomRadioGroup label="g" v-model="genderSelected" :options="genderClass"/>
        <CustomRadioGroup label="n" v-model="numberSelected" :options="numberClass"/>
        <CustomRadioGroup label="c" v-model="caseSelected" :options="caseClass"/>
      </div>
      <div v-if="isWordClass('xPQ')" class="d-flex text-left">
        <CustomRadioGroup label="g" v-model="genderSelected" :options="genderClass"/>
        <CustomRadioGroup label="n" v-model="numberSelected" :options="numberClass"/>
        <CustomRadioGroup label="c" v-model="caseSelected" :options="caseClass"/>
      </div>
      <div v-if="isWordClass('xPI')" class="d-flex text-left">
        <CustomRadioGroup label="g" v-model="genderSelected" :options="genderClass"/>
        <CustomRadioGroup label="n" v-model="numberSelected" :options="numberClass"/>
        <CustomRadioGroup label="c" v-model="caseSelected" :options="caseClass"/>
      </div>
      <div v-if="isWordClass('xDP')" class="d-flex text-left">
        <CustomRadioGroup label="r" v-model="gradeSelected" :options="gradeClass"/>
        <CustomRadioGroup label="g" v-model="genderSelected" :options="genderClass"/>
        <CustomRadioGroup label="n" v-model="numberSelected" :options="numberClass"/>
        <CustomRadioGroup label="c" v-model="caseSelected" :options="caseClass"/>
        <CustomRadioGroup label="s" v-model="speciesSelected" :options="speciesClass"/>
      </div>
      <div v-if="isWordClass('xDD')" class="d-flex text-left">
        <CustomRadioGroup label="g" v-model="genderSelected" :options="genderClass"/>
        <CustomRadioGroup label="n" v-model="numberSelected" :options="numberClass"/>
        <CustomRadioGroup label="c" v-model="caseSelected" :options="caseClass"/>
      </div>
      <div v-if="isWordClass('xDQ')" class="d-flex text-left">
        <CustomRadioGroup label="g" v-model="genderSelected" :options="genderClass"/>
        <CustomRadioGroup label="n" v-model="numberSelected" :options="numberClass"/>
        <CustomRadioGroup label="c" v-model="caseSelected" :options="caseClass"/>
      </div>
      <div v-if="isWordClass('xPD')" class="d-flex text-left">
        <CustomRadioGroup label="p" v-model="personSelected" :options="personClass"/>
        <CustomRadioGroup label="g" v-model="genderSelected" :options="genderClass"/>
        <CustomRadioGroup label="n" v-model="numberSelected" :options="numberClass"/>
        <CustomRadioGroup label="c" v-model="caseSelected" :options="caseClass"/>
      </div>
      <div v-if="isWordClass('xNA')" class="d-flex text-left">
        <CustomRadioGroup label="g" v-model="genderSelected" :options="genderClass"/>
        <CustomRadioGroup label="n" v-model="numberSelected" :options="numberClass"/>
        <CustomRadioGroup label="c" v-model="caseSelected" :options="caseClass"/>
        <CustomRadioGroup label="s" v-model="speciesSelected" :options="speciesClass"/>
      </div>
      <div v-if="isWordClass('xNO')" class="d-flex text-left">
        <CustomRadioGroup label="g" v-model="genderSelected" :options="genderClass"/>
        <CustomRadioGroup label="n" v-model="numberSelected" :options="numberClass"/>
        <CustomRadioGroup label="c" v-model="caseSelected" :options="caseClass"/>
        <CustomRadioGroup label="s" v-model="speciesSelected" :options="speciesClass"/>
      </div>
      <div v-if="isWordClass('xVB')" class="d-flex text-left">
        <CustomRadioGroup label="f" v-model="finitenessSelected" :options="finitenessClass"/>
        <div v-if="isFinitenessClass('fF')">
          <CustomRadioGroup label="t" v-model="tenseSelected" :options="tenseClass"/>
          <CustomRadioGroup label="m" v-model="moodSelected" :options="moodClass"/>
          <CustomRadioGroup label="p" v-model="personSelected" :options="personClass"/>
          <CustomRadioGroup label="n" v-model="numberSelected" :options="numberClass"/>
          <CustomRadioGroup label="v" v-model="voiceSelected" :options="voiceClass"/>
          <CustomRadioGroup label="i" v-model="inflectionalSelected" :options="inflectionalClass"/>
        </div>
        <div v-if="isFinitenessClass('fP')" class="d-flex text-left">
          <CustomRadioGroup label="t" v-model="tenseSelected" :options="tenseClass"/>
          <CustomRadioGroup label="v" v-model="voiceSelected" :options="voiceClass"/>
          <CustomRadioGroup label="g" v-model="genderSelected" :options="genderClass"/>
          <CustomRadioGroup label="n" v-model="numberSelected" :options="numberClass"/>
          <CustomRadioGroup label="c" v-model="caseSelected" :options="caseClass"/>
          <CustomRadioGroup label="s" v-model="speciesSelected" :options="speciesClass"/>
        </div>
        <div v-if="isFinitenessClass('fI')" class="d-flex text-left">
          <CustomRadioGroup label="t" v-model="tenseSelected" :options="tenseClass"/>
          <CustomRadioGroup label="v" v-model="voiceSelected" :options="voiceClass"/>
          <CustomRadioGroup label="i" v-model="inflectionalSelected" :options="inflectionalClass"/>
        </div>

      </div>
      <div v-if="isWordClass('xAV')" class="d-flex text-left">
        <CustomRadioGroup label="r" v-model="gradeSelected" :options="gradeClass"/>
      </div>
      <div v-if="isWordClass('xAT')" class="d-flex text-left">
        <CustomRadioGroup label="g" v-model="genderSelected" :options="genderClass"/>
        <CustomRadioGroup label="n" v-model="numberSelected" :options="numberClass"/>
        <CustomRadioGroup label="c" v-model="caseSelected" :options="caseClass"/>
        <CustomRadioGroup label="s" v-model="speciesSelected" :options="speciesClass"/>
      </div>
      <div v-if="isWordClass('xAP')" class="d-flex text-left">
        <CustomRadioGroup label="pg" v-model="prepositionGovernmentSelected" :options="prepositionGovernmentClass"/>
      </div>
      <div v-if="isWordClass('xCC')" class="d-flex text-left">
      </div>
      <div v-if="isWordClass('xCS')" class="d-flex text-left">
        <CustomRadioGroup label="g" v-model="conjunctionGovernmentSelected" :options="conjunctionGovernmentClass"/>
      </div>
<!--      <div v-if="isWordClass('xIT')" class="d-flex text-left">-->
<!--      </div>-->
<!--      <div v-if="isWordClass('xIM')" class="d-flex text-left">-->
<!--      </div>-->
<!--      <div v-if="isWordClass('xRP')" class="d-flex text-left">-->
<!--      </div>-->
<!--      <div v-if="isWordClass('xEX')" class="d-flex text-left">-->
<!--      </div>-->
<!--      <div v-if="isWordClass('xUA')" class="d-flex text-left">-->
<!--      </div>-->
      </b-col>
    </b-row>
  </b-container>

</template>

<script>
import CustomRadioGroup from "@/components/CustomRadioGroup";
export default {
name: "PosMenota",
  components: {
  CustomRadioGroup
  },
  data: function() {
    return {
      wordClass: "",
      showingCopyMessage: false,
      wordClasses: ["xNC", "xNP", "xAJ", "xPE", "xPQ", "xPI", "xDP", "xDD", "xDQ", "xPD", "xNA", "xNO", "xVB", "xAV",
        "xAT", "xAP", "xCC", "xCS", "xIT", "xIM", "xRP", "xUA"],
      inflectionalSelected: "",
      inflectionalClass: [
          {text: "Strong", value: "iST", disabled: false},
          {text: "Weak", value: "iWK", disabled: false},
          {text: "Reduplicating", value: "iRD", disabled: false},
          {text: "Preterite-Present", value: "iPP", disabled: false},
          {text: "Unspecified", value: "iU", disabled: false},
      ],
      genderSelected: "",
      genderClass: [
        {text: "Masculine", value: "gM", disabled: false},
        {text: "Feminine", value: "gF", disabled: false},
        {text: "Neuter", value: "gN", disabled: false},
        {text: "Unspecified", value: "gU", disabled: false},
        {text: "Masculine Feminine", value: "gMF", disabled: false},
        {text: "Masculine Neuter", value: "gMN", disabled: false},
        {text: "Feminine Neuter", value: "gFN", disabled: false},
        {text: "Masculine Feminine Neuter", value: "gMFN", disabled: false},
      ],
      numberSelected: "",
      numberClass: [
          {text: "Singular", value: "nS", disabled: false},
          {text: "Dual", value: "nD", disabled: false},
          {text: "Plural", value: "nP", disabled: false},
          {text: "Unspecified", value: "nU", disabled: false},
      ],
      caseSelected: "",
      caseClass: [
          {text: "Nominative", value: "cN", disabled: false},
          {text: "Genitive", value: "cG", disabled: false},
          {text: "Dative", value: "cD", disabled: false},
          {text: "Accusative", value: "cA", disabled: false},
          {text: "Unspecified", value: "cU", disabled: false},
          {text: "Accusative Dative", value: "cAD", disabled: false},
          {text: "Genitive Dative", value: "cGD", disabled: false},
          {text: "Accusative Nominative", value: "cAN", disabled: false},
          {text: "Accusative Genitive", value: "cAG", disabled: false},
          {text: "Oblique", value: "cO", disabled: false},
      ],
      speciesSelected: "",
      speciesClass: [
          {text: "Indefinite", value: "sI", disabled: false},
          {text: "Definite", value: "sD", disabled: false},
          {text: "Unspecified", value: "sU", disabled: false},
      ],
      gradeSelected: "",
      gradeClass: [
          {text: "Positive", value: "rP", disabled: false},
          {text: "Comparative", value: "rC", disabled: false},
          {text: "Superlative", value: "rS", disabled: false},
          {text: "Unspecified", value: "rU", disabled: false},
      ],
      personSelected: "",
      personClass: [
          {text: "First person", value: "p1", disabled: false},
          {text: "Second person", value: "p2", disabled: false},
          {text: "Third person", value: "p3", disabled: false},
          {text: "Unspecified", value: "pU", disabled: false},
      ],
      tenseSelected: "",
      tenseClass: [
          {text: "Present", value: "tPS", disabled: false},
          {text: "Preterite", value: "tPT", disabled: false},
          {text: "Unspecified", value: "tU", disabled: false},
      ],
      moodSelected: "",
      moodClass: [
          {text: "Indicative", value: "mIN", disabled: false},
          {text: "Subjunctive", value: "mSU", disabled: false},
          {text: "Imperative", value: "mIP", disabled: false},
          {text: "Unspecified", value: "mU", disabled: false},
          {text: "Indicative Subjunctive", value: "mINSU", disabled: false},
          {text: "Indicative Imperative", value: "mINIM", disabled: false},
          {text: "Subjunctive Imperative", value: "mSUIM", disabled: false},
      ],
      voiceSelected: "",
      voiceClass: [
          {text: "Active", value: "vA", disabled: false},
          {text: "Reflexive", value: "vR", disabled: false},
          {text: "Unspecified", value: "vU", disabled: false},
      ],
      finitenessSelected: "",
      finitenessClass: [
          {text: "Finite", value: "fF", disabled: false},
          {text: "Infinite", value: "fI", disabled: false},
          {text: "Participle", value: "fP", disabled: false},
          {text: "Unspecified", value: "fU", disabled: false},
      ],
      encliticsSelected: "",
      encliticsClass: [
          {text: "Enclitic pronoun", value: "eP", disabled: false},
          {text: "Enclitic negative particle", value: "eN", disabled: false},
      ],
      prepositionGovernmentSelected: "",
      prepositionGovernmentClass: [
          {text: "Governing genitive", value: "yG", disabled: false},
          {text: "Governing dative", value: "yD", disabled: false},
          {text: "Governing accusative", value: "yA", disabled: false},
          {text: "Unspecified", value: "yU", disabled: false},
      ],
      conjunctionGovernmentSelected: "",
      conjunctionGovernmentClass: [
          {text: "Governing indicative", value: "yIN", disabled: false},
          {text: "Governing subjunctive", value: "ySU", disabled: false},
          {text: "Unspecified government", value: "yU", disabled: false},
      ]
    }
  },
  methods: {
    isWordClass: function(other) {
      return this.wordClass === other;
    },
    isFinitenessClass: function (other)  {
      return this.finitenessSelected === other;
    },
    refresh: function() {
      this.wordClass = "";
      this.inflectionalSelected = "";
      this.genderSelected = "";
      this.numberSelected = "";
      this.caseSelected = "";
      this.speciesSelected = "";
      this.gradeSelected = "";
      this.personClass = "";
      this.tenseSelected = "";
      this.moodSelected = "";
      this.voiceSelected = "";
      this.finitenessSelected = "";
      this.encliticsSelected = "";
      this.prepositionGovernmentSelected = "";
      this.conjunctionGovernmentSelected = "";
    },
    copyRendering: function() {
      let copiedTextInput = document.querySelector("#rendering");
      copiedTextInput.setAttribute("type", "text");
      copiedTextInput.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        console.log("copy failed");
      }
      copiedTextInput.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();
      this.showingCopyMessage = true
      setTimeout(() => {
         this.showingCopyMessage = false;
      }, 3000);

    }
  },
  computed: {
    rendering: function() {
      switch (this.wordClass) {
        case "xNC":
          return this.wordClass + " " + this.genderSelected + " " + this.numberSelected + " " + this.caseSelected + " " + this.speciesSelected;
        case "xNP":
          return this.wordClass + " " + this.genderSelected + " " + this.numberSelected + " " + this.caseSelected + " " + this.speciesSelected;
        case "xAJ":
          return this.wordClass + " " + this.gradeSelected + " " + this.genderSelected + " " + this.numberSelected + " " + this.caseSelected + " " + this.speciesSelected;
        case "xPE":
          return this.wordClass + " " + this.personSelected + " " + this.genderSelected + " " + this.numberSelected + " " + this.caseSelected;
        case "xPQ":
          return this.wordClass + " " + this.genderSelected + " " + this.numberSelected + " " + this.caseSelected;
        case "xPI":
          return this.wordClass + " " + this.genderSelected + " " + this.numberSelected + " " + this.caseSelected;
        case "xDP":
          return this.wordClass + " " + this.gradeSelected + " " + this.genderSelected + " " + this.numberSelected + " " + this.caseSelected + " " + this.speciesSelected;
        case "xVB":
          switch (this.finitenessSelected) {
            case "fF":
              return this.wordClass + " " + this.tenseSelected + " " + this.moodSelected + " " + this.personSelected + " " + this.numberSelected + " " + this.voiceSelected + " " + this.inflectionalSelected;
            case "fP":
              return this.wordClass + " " + this.tenseSelected + " " + this.voiceSelected + " " + this.genderSelected + " " + this.numberSelected + " " + this.caseSelected + " " + this.speciesSelected;
            case "fI":
              return this.wordClass + " " + this.tenseSelected + " " + this.voiceSelected + " " + this.inflectionalSelected;
          }
          break;
        case "xDD":
          return this.wordClass + " " + this.genderSelected + " " + this.numberSelected + " " + this.caseSelected;
        case "xDQ":
          return this.wordClass + " " + this.genderSelected + " " + this.numberSelected + " " + this.caseSelected;
        case "xPD":
          return this.wordClass + " " + this.personSelected + " " + this.genderSelected + " " + this.numberSelected + " " + this.caseSelected;
        case "xNA":
          return this.wordClass + " " + this.genderSelected + " " + this.numberSelected + " " + this.caseSelected + " " + this.speciesSelected;
        case "xNO":
          return this.wordClass + " " + this.genderSelected + " " + this.numberSelected + " " + this.caseSelected + " " + this.speciesSelected;
        case "xAV":
          return this.wordClass + " " + this.gradeSelected;
        case "AT":
          return this.wordClass + " " + this.genderSelected + " " + this.numberSelected + " " + this.caseSelected + " " + this.speciesSelected;
        case "xAP":
          return this.wordClass + " " + this.prepositionGovernmentSelected;
        case "CC":
          return this.wordClass + " " + this.genderSelected + " " + this.numberSelected + " " + this.caseSelected + " " + this.speciesSelected;
        case "CS":
          return this.wordClass + " " + this.genderSelected + " " + this.numberSelected + " " + this.caseSelected + " " + this.speciesSelected;
        default:
          return this.wordClass;
      }
      return "";
    }
  }
}
</script>

<style scoped>

</style>