<template>
  <b-form-group :label="label" class="m-3 p-2">
    <b-form-radio-group stacked @input="updateValue" :options="options" ></b-form-radio-group>
  </b-form-group>
</template>

<script>
export default {
name: "CustomRadioGroup",
  props: ["label", "value", "options"],
  model: {
    prop: "checked",
    event: "change"
  },
  methods: {
    updateValue: function(newValue) {
      console.log("nouvelle valeur "+newValue);
      this.$emit('change', newValue);
    },
  }
}
</script>

<style scoped>

</style>