

const localStorage = {
    keys: {
        type: Array,
        default: []
    },
    methods: {
        generateNewSessionKey() {
           return `${Date.now()}`;
        },
        loadLocaleSessionByKey() {

        },

        load() {

        },
        getSessionKeys() {

        }
    }

};

export default localStorage;
