<template>
  <b-container>

    <b-form-group label="Processes:" v-slot="{ ariaDescribedby }">
      <b-form-checkbox-group
        id="checkbox-group-2"
        v-model="selectedProcesses"
        :aria-describedby="ariaDescribedby"
        name="flavour-2"
      >
<!--        <b-form-checkbox value="facsimile">Fac simile annotation</b-form-checkbox>-->
<!--        <b-form-checkbox value="diploma">Diploma annotation</b-form-checkbox>-->
        <b-form-checkbox value="tokenisation">Tokenisation</b-form-checkbox>
        <b-form-checkbox value="lemmatisation">Lemmatisation</b-form-checkbox>
        <b-form-checkbox value="pos"><i>Pars oratori</i></b-form-checkbox>
      </b-form-checkbox-group>
    </b-form-group>
    <p style="text-align: start" v-html="processDescription"/>

    <div class="accordion" role="tablist">
      <!-- input -->
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-input variant="info">Input</b-button>
        </b-card-header>
        <b-collapse id="accordion-input" visible accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>
              <b-form-group label="The text is composed of tokens. Paragraphs are delimited by two new lines or more,
              sentences begins with a capital character and finished by a sentence delimiter.
              Tokens are most of the time delimited by blanks.">
              <b-textarea :disabled="inputLocked" v-model="raw" rows="10" max-rows="20" size="sm"/>
              <b-button @click="lockInput" class="m-2">{{ lockLabel }}</b-button>
              <b-button @click="tokenize" :disabled="!inputLocked" class="m-2">Process</b-button>
              </b-form-group>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <!-- Facsimile-->
      <b-card no-body class="mb-1" v-if="keepProcess('facsimile')">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-facsimile variant="info">Facsimile</b-button>
        </b-card-header>
        <b-collapse id="accordion-facsimile" visible accordion="my-accordion" role="tabpanel">
          <b-card-body>
<!--            <b-card-text>-->
<!--              <b-textarea v-model="textInput" rows="10" max-rows="20" size="sm"/>-->
<!--            </b-card-text>-->
          </b-card-body>
        </b-collapse>
      </b-card>
      <!-- Diploma -->
      <b-card no-body class="mb-1" v-if="keepProcess('diploma')">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-diploma variant="info">Diploma</b-button>
        </b-card-header>
        <b-collapse id="accordion-diploma" visible accordion="my-accordion" role="tabpanel">
          <b-card-body>
<!--            <b-card-text>-->
<!--              <b-textarea v-model="textInput" rows="10" max-rows="20" size="sm"/>-->
<!--            </b-card-text>-->
          </b-card-body>
        </b-collapse>
      </b-card>

      <!-- Tokenise -->
      <b-card no-body class="mb-1" v-if="keepProcess('tokenisation')">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-tokenize variant="info">Tokenise</b-button>
        </b-card-header>
        <b-collapse id="accordion-tokenize" visible accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>
              <p v-html="tokenized_text"/>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <!-- Lemmatisation -->
      <b-card no-body class="mb-1" v-if="keepProcess('lemmatisation')">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-lemmatisation variant="info">Lemmatisation</b-button>
        </b-card-header>
        <b-collapse id="accordion-lemmatisation" visible accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>
              <p>For each token, give its lemma</p>
              <b-row>
                <p>Context</p>
                <b-textarea disabled rows="3" v-model="contextLemmatisation"/>
              </b-row>
              <b-row class="my-3">
                <b-col>
                  <b-button @click="goToPreviousTokenForLemma">Previous token</b-button>
                </b-col>
                <b-col><span>{{ previousTokenForAnalysis }}</span></b-col>
                <b-col>
                  <b-input disabled v-model="selectedToken"/>
                </b-col>
                <b-col><span>{{ nextTokenForAnalysis }}</span></b-col>
                <b-col>
                  <b-button @click="goToNextTokenForLemma">Next token</b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Lemma">
                    <b-input v-model="selectedLemma"/>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-button @click="lookup">Lookup</b-button>
                </b-col>
              </b-row>
              <b-row class="my-3">
                <p>Dictionary</p>
<!--                <b-col>-->
<!--                  <b-input v-model="tokenToLookup"/>-->
<!--                </b-col>-->
                <b-col>
                  <b-textarea disabled id="definition" v-model="definitionFound"/>
                  <b-button @click="copy" id="copy-def-btn">Copy</b-button>
                  <b-tooltip target="copy-def-btn" triggers="manual" placement="bottom"
                             :show="showingDefinitionCopyMessage">Copied</b-tooltip>
                </b-col>
              </b-row>
              <b-row>
                <b-button @click="exportLemmaAnnotations">Export</b-button>
              </b-row>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <!-- POS -->
      <b-card no-body class="mb-1" v-if="keepProcess('pos')">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.accordion-pars-oratoria variant="info"><i>Pars oratoria</i></b-button>
        </b-card-header>
        <b-collapse id="accordion-pars-oratoria" visible accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>
              <p>For each token, give its <i>pars oratoria</i></p>
<!--              <b-textarea v-model="textInput" rows="10" max-rows="20" size="sm"/>-->

              <b-row>
                <p>Context</p>
                <b-textarea disabled rows="3" v-model="contextLemmatisation"/>
              </b-row>
              <b-row class="my-3">
                <b-col>
                  <b-button @click="goToPreviousTokenForLemma">Previous token</b-button>
                </b-col>
                <b-col><span>{{ previousTokenForAnalysis }}</span></b-col>
                <b-col>
                  <b-input disabled v-model="selectedToken"/>
<!--                  <b-button to="/reginsmal/menota-pos-annotator">Format POS tag</b-button>-->
                  <PosMenota/>
                </b-col>
                <b-col><span>{{ nextTokenForAnalysis }}</span></b-col>
                <b-col>
                  <b-button @click="goToNextTokenForLemma">Next token</b-button>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

    </div>
  </b-container>
</template>

<script>
import {requestLookupDictionary, requestTokenize} from "@/services/api";
// import {generateNewSessionKey} from "@/services/localeSessionManagement";
import {mapMutations, mapState} from 'vuex';
import {getNext, getPrev} from "@/services/textManagement";
import { saveAs } from 'file-saver';
import PosMenota from "@/components/PosMenota";
export default {
  name: "Annotator",
  components: {PosMenota},
  data: function() {
    return {
      selectedProcesses: ["tokenisation", "lemmatisation", "pos"],
      inputLocked: false,
      // region tokenisation
      //STORE tokenized: [],  // list (paragraphs) of list (sentences) of list (tokens)
      lockLabel: 'Lock',
      // tokenizedHtml: "",
      // endregion
      // region lemmatisation
      selectedParagraphId: 0,
      selectedSentenceId: 0,
      selectedTokenId: 0,
      selectedLemma: '',
      tokenToLookup: '',
      definitionFound: '',
      //STORE annotatedLemmata: [], // it must have the same structure as tokenized

      showingDefinitionCopyMessage: false,
      // endregion
      // region part of speech
      selectedPOS: '',
      // endregion

      sessionKey: null
    }
  },
  mounted() {
    // if(this.sessionKey !== null) {
      // this.sessionKey = this.$localStorage.generateNewSessionKey();
      // this.
    // }
  },
  methods: {
    ...mapMutations(["updateRaw", "updateTokenized", "updateAnnotatedLemma", "updateAnnotatedPOS"]),
    tokenize() {
      requestTokenize(this.raw).then(
          response => {
            if(response.data.success) {
              this.updateTokenized({tokenized: response.data.tokenized_text});
              this.selectedTokenId = 0;
              this.selectedSentenceId = 0;
              this.selectedParagraphId = 0;
              // this.$localStorage.get('');
            } else {
              this.tokenized = [];
            }
          }
      )
    },
    copy: function() {
      let copiedTextInput = document.querySelector("#definition");
      copiedTextInput.setAttribute("type", "text");
      copiedTextInput.select();
      try {
        document.execCommand("copy");
        console.log("copied");
      } catch (err) {
        console.log("copy failed");
      }
      copiedTextInput.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();
      this.showingDefinitionCopyMessage = true
      setTimeout(() => {
         this.showingDefinitionCopyMessage = false;
      }, 3000);
    },
    goToPreviousToken() {
      this.tokenized.length;
    },
    goToNextToken() {

    },

    requestLemmatisation() {

    },
    keepProcess(processName) {
      return this.selectedProcesses.includes(processName);
    },
    lockInput() {
      this.inputLocked = !this.inputLocked;
      if(this.inputLocked) {
        this.lockLabel = "Unlock";
      } else {
        this.lockLabel = "Lock";
      }
    },

    goToPreviousTokenForLemma() {
      const res = getPrev(this.tokenized, this.selectedParagraphId, this.selectedSentenceId, this.selectedTokenId);
      if(res !== null) {
        this.selectedParagraphId = res[0];
        this.selectedSentenceId = res[1];
        this.selectedTokenId = res[2];
      }
    },

    goToNextTokenForLemma() {
      const res = getNext(this.tokenized, this.selectedParagraphId, this.selectedSentenceId, this.selectedTokenId);
      if(res !== null) {
        this.annotatedLemmata[this.selectedParagraphId][this.selectedSentenceId][this.selectedTokenId] = this.selectedLemma;
        this.annotatedPOS[this.selectedParagraphId][this.selectedSentenceId][this.selectedTokenId] = this.selectedPOS;
        this.selectedLemma = this.annotatedLemmata[this.selectedParagraphId][this.selectedSentenceId][this.selectedTokenId];
        this.selectedPOS = this.annotatedPOS[this.selectedParagraphId][this.selectedSentenceId][this.selectedTokenId];
        this.updateAnnotatedLemma({annotatedLemmata: this.annotatedLemmata});
        this.selectedParagraphId = res[0];
        this.selectedSentenceId = res[1];
        this.selectedTokenId = res[2];
      }
    },
    lookup() {
      requestLookupDictionary(this.selectedLemma).then(
          response => {
            if(response.data.success) {
              this.definitionFound = response.data.definition;
            }
          }
      )
    },
    exportLemmaAnnotations() {
      let textFile = null;
      let parts = [];
      console.log(this.tokenized.length);
      for(let i = 0; i < this.tokenized.length; i++) {
        console.log(this.tokenized[i].length);
        for(let j = 0; j < this.tokenized[i].length; j++) {
          for(let k = 0; k < this.tokenized[i][j].length; k++) {
            parts.push(`${this.tokenized[i][j][k]}/${this.annotatedLemmata[i][j][k]}`);
          }
          parts.push("\n");
        }
        parts.push("\n");
      }
      console.log(parts);
      let data = new Blob([parts.join(" ")], {type: 'text/plain'});
      // if(textFile !== null) {
        window.URL.revokeObjectURL(textFile);
      // }
      textFile = window.URL.createObjectURL(data);
      saveAs(data, "lemmata-annotations.txt");

    },
    exportPOSAnnotations() {
      let textFile = null;
      let parts = [];
      console.log(this.tokenized.length);
      for(let i = 0; i < this.tokenized.length; i++) {
        console.log(this.tokenized[i].length);
        for(let j = 0; j < this.tokenized[i].length; j++) {
          for(let k = 0; k < this.tokenized[i][j].length; k++) {
            parts.push(`${this.tokenized[i][j][k]}/${this.annotatedPOS[i][j][k]}`);
          }
          parts.push("\n");
        }
        parts.push("\n");
      }
      console.log(parts);
      let data = new Blob([parts.join(" ")], {type: 'text/plain'});
      // if(textFile !== null) {
        window.URL.revokeObjectURL(textFile);
      // }
      textFile = window.URL.createObjectURL(data);
      saveAs(data, "pos-annotations.txt");

    },
  },
  computed: {
    ...mapState(['raw', 'tokenized', 'annotatedLemmata', "annotatedPOS"]),
    raw: {
      get() {
        return this.$store.state.raw;
      },
      set(value) {
        this.updateRaw(value);
      }
    },
    tokenList: function () {
      return this.tokenized.flat(2);
    },
    tokenized_text: function () {
      if(this.tokenList.length > 0) {
        return this.tokenList.join(" ")
      } else {
        return "";
      }
    },
    selectedToken() {
      if(this.tokenized.length > this.selectedParagraphId &&
          this.tokenized[this.selectedParagraphId].length > this.selectedSentenceId &&
          this.tokenized[this.selectedParagraphId][this.selectedSentenceId].length > this.selectedTokenId) {
        return this.tokenized[this.selectedParagraphId][this.selectedSentenceId][this.selectedTokenId];
      } else {
        return "";
      }
    },
    contextLemmatisation() {
      if(this.tokenized.length > this.selectedParagraphId &&
          this.tokenized[this.selectedParagraphId].length > this.selectedSentenceId) {
        return this.tokenized[this.selectedParagraphId][this.selectedSentenceId].join(" ");
      }
      return '';
    },
    previousTokenForAnalysis() {
      const res =  getPrev(this.tokenized, this.selectedParagraphId, this.selectedSentenceId, this.selectedTokenId);
      if(res === null) {
        return "";
      }
      return this.tokenized[res[0]][res[1]][res[2]];
    },
    nextTokenForAnalysis() {
      const res = getNext(this.tokenized, this.selectedParagraphId, this.selectedSentenceId, this.selectedTokenId);
      if(res === null) {
        return "";
      }
      return this.tokenized[res[0]][res[1]][res[2]];
    },
    processDescription: function () {
      let text = [];
      if(this.selectedProcesses.includes("facsimile")) {
        text.push("Fac simile annotation sare expected");
      }
      if(this.selectedProcesses.includes("diploma")) {
        text.push("Diploma annotations are expected.");
      }

      if(this.selectedProcesses.includes("tokenisation")) {
        text.push("The text is not already tokenised so it is going to be tokenised.");
      } else {
        text.push("The text is assumed to be tokenised.");
      }

      if(this.selectedProcesses.includes("lemmatisation")) {
        text.push("Lemmatisation annotation process is ready.")
      }

      if(this.selectedProcesses.includes("pos")) {
        text.push("Parts of speech annotation process is ready")
      }
      return text.join("<br/>");
    }
  },
}
</script>

<style scoped>

</style>