import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueLocalStorage from 'vue-localstorage'

import router from "@/router";
import axios from "axios";
import store from './store'
import localStorage from "@/services/localeSessionManagement";
Vue.use(VueLocalStorage)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

axios.defaults.baseURL = process.env.VUE_APP_SITE_API_URL;
// axios.defaults.baseURL = "http://127.0.0.1:5000/reginsmal";

new Vue({
  router,
  store,
  localStorage,
  beforeCreate() {
    this.$store.commit('');
  },
  render: h => h(App),
}).$mount('#app')
